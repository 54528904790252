import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from 'Common/components/buttons/Button';
import { DefaultTable, TableBodyCell, TableHeadCell } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import EditableTile from 'Common/components/Tile/EditableTile';
import formatDate from 'Common/utils/formatDate';

const FooterWrapper = styled.div`
	width: 100%;
	padding: 15px;
	display: flex;
	justify-content: center;
`;

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const UtilityRateView = ({ utilityRateHistory, utilityRateName, onCancel }) => {
	const { t } = useTranslation();
	const data = useMemo(
		() =>
			utilityRateHistory?.map(rate => ({
				...rate,
				createdDate: formatDate(rate.createdDate, 'yyyy/MM/dd'),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, utilityRateHistory],
	);

	const columns = useMemo(
		() => [
			{
				Header: t('Rate'),
				accessor: 'rate',
			},
			{
				Header: t('Date'),
				accessor: 'createdDate',
			},
		],
		[t],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
				getCustomRowStyles: () => ({
					height: 'auto',
				}),
			},
			useCustomRowStyle,
			useCustomCellStyles,
		);
	return (
		<div>
			<EditableTile
				title={t('Grid utility rate log: ') + utilityRateName}
				isTable
				customStyle={{ fontSize: '20px', textTransform: 'unset' }}
			>
				<TableWrapper>
					<DefaultTable
						getTableProps={getTableProps}
						getTableHeaderProps={getTableHeaderProps}
						getTableBodyProps={getTableBodyProps}
						rows={rows}
						headerGroups={headerGroups}
						prepareRow={prepareRow}
					/>
				</TableWrapper>
			</EditableTile>
			<FooterWrapper>
				<Button secondary text={t('Close')} onClick={onCancel} label={'Cancel Button'} />
			</FooterWrapper>
		</div>
	);
};

UtilityRateView.defaultProps = {
	onCancel: () => {},
	utilityRateHistory: undefined,
	utilityRateName: undefined,
};

UtilityRateView.propTypes = {
	onCancel: PropTypes.func,
	utilityRateHistory: PropTypes.func,
	utilityRateName: PropTypes.func,
};

export default UtilityRateView;
