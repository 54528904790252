import PropTypes from 'prop-types';
import ModalForm from 'Common/components/form/ModalForm';
import UtilityRateView from '../Form/UtilityRateView';
import { useCallback, useEffect } from 'react';
import getTariffTypeHistory from 'Invoice/api/getTariffTypeHistory';
import { useState } from 'react';
import showToastError from 'Common/utils/showToastError';

// eslint-disable-next-line
const UtilityRateViewModal = ({ isOpen, onClose, utilityRateName }) => {
	const fetchUtilityRatesHistory = useCallback(async () => {
		if (!utilityRateName) return;
		try {
			const response = await getTariffTypeHistory(utilityRateName);
			setUtilityRateHistory(response.data);
		} catch (err) {
			showToastError(err);
		}
	}, [utilityRateName]);

	useEffect(() => {
		fetchUtilityRatesHistory();
	}, [fetchUtilityRatesHistory]);

	const [utilityRateHistory, setUtilityRateHistory] = useState([]);

	return (
		<div>
			<ModalForm
				label="utility-rate-view"
				isOpen={isOpen}
				onClose={onClose}
				customStyle={{ margin: 0, padding: '10px 44px', background: '#F7F7F7' }}
			>
				{() => (
					<UtilityRateView
						utilityRateHistory={utilityRateHistory}
						utilityRateName={utilityRateName}
						onCancel={onClose}
					/>
				)}
			</ModalForm>
		</div>
	);
};
UtilityRateViewModal.defaultProps = {
	utilityRateName: '',
	isOpen: false,
	onClose: () => {},
};
UtilityRateViewModal.propTypes = {
	utilityRateName: PropTypes.string,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
};

export default UtilityRateViewModal;
