export const roles = {
	ADMIN: 'ROLE_ADMIN',
	FINANCE: 'ROLE_FINANCE',
	HEAD_OF_FINANCE: 'ROLE_HEAD_OF_FINANCE',
	ASSET_MANAGER: 'ROLE_ASSET_MANAGER',
	ASSET_MARKETING: 'ROLE_ASSET_MARKETING',
	MANAGEMENT: 'ROLE_MANAGEMENT',
	PM_FUNDRAISING: 'ROLE_PM_FUNDRAISING',
	PM_TECHNICAL: 'ROLE_PM_TECHNICAL',
	SALES: 'ROLE_SALES',
	EPC_PARTNER: 'ROLE_EPC_PARTNER',
	HEAD_OF_ASSET_MANAGEMENT: 'ROLE_HEAD_OF_ASSET_MANAGEMENT',
	HEAD_OF_MARKETING: 'ROLE_HEAD_OF_MARKETING',
	HEAD_OF_FUNDRAISING: 'ROLE_HEAD_OF_FUNDRAISING',
	HEAD_OF_TECH: 'ROLE_HEAD_OF_TECH',
	HEAD_OF_SALES: 'ROLE_HEAD_OF_SALES',
	HEAD_OF_ESG: 'ROLE_HEAD_OF_ESG',
	HEAD_OF_REGION: 'ROLE_HEAD_OF_REGION',
	HEAD_OF_EXCELLENCE: 'ROLE_HEAD_OF_EXCELLENCE',
	PRODUCT_LEAD: 'ROLE_PRODUCT_LEAD',
	DATA_LEAD: 'ROLE_DATA_LEAD',
	OPERATIONAL_EXCELLENCE_LEAD: 'ROLE_OPERATIONAL_EXCELLENCE_LEAD',
	ESG: 'ROLE_ESG',
};

export const roleLabels = {
	ROLE_ADMIN: 'Admin',
	ROLE_FINANCE: 'Finance',
	ROLE_HEAD_OF_FINANCE: 'Head of Finance',
	ROLE_ASSET_MANAGER: 'Asset Management',
	ROLE_ASSET_MARKETING: 'Marketing',
	ROLE_MANAGEMENT: 'Management',
	ROLE_PM_FUNDRAISING: 'Fundraising',
	ROLE_PM_TECHNICAL: 'Tech',
	ROLE_SALES: 'Sales',
	ROLE_EPC_PARTNER: 'EPC Partner',
	ROLE_HEAD_OF_ASSET_MANAGEMENT: 'Head of Asset Management',
	ROLE_HEAD_OF_MARKETING: 'Head of Marketing',
	ROLE_HEAD_OF_FUNDRAISING: 'Head of Fundraising',
	ROLE_HEAD_OF_TECH: 'Head of Tech',
	ROLE_HEAD_OF_SALES: 'Head of Sales',
	ROLE_HEAD_OF_ESG: 'Head of ESG',
	ROLE_HEAD_OF_REGION: 'Head of Region',
	ROLE_HEAD_OF_EXCELLENCE: 'Head of Excellence',
	ROLE_PRODUCT_LEAD: 'Product Lead',
	ROLE_DATA_LEAD: 'Data Lead',
	ROLE_OPERATIONAL_EXCELLENCE_LEAD: 'Operational Excellence Lead',
	ROLE_ESG: 'ESG',
};

export const roleGroups = {
	ECOLIGO: 'ECOLIGO',
	EPC_PARTNER: 'EPC_PARTNER',
};

export const rolesByRoleGroup = {
	[roleGroups.ECOLIGO]: [
		'ROLE_ADMIN',
		'ROLE_FINANCE',
		'HEAD_OF_FINANCE',
		'ROLE_ASSET_MANAGER',
		'ROLE_ASSET_MARKETING',
		'ROLE_MANAGEMENT',
		'ROLE_PM_FUNDRAISING',
		'ROLE_PM_TECHNICAL',
		'ROLE_SALES',
		'ROLE_HEAD_OF_ASSET_MANAGEMENT',
		'ROLE_HEAD_OF_MARKETING',
		'ROLE_HEAD_OF_FUNDRAISING',
		'ROLE_HEAD_OF_TECH',
		'ROLE_HEAD_OF_SALES',
		'ROLE_HEAD_OF_ESG',
		'ROLE_HEAD_OF_REGION',
		'ROLE_HEAD_OF_EXCELLENCE',
		'ROLE_PRODUCT_LEAD',
		'ROLE_DATA_LEAD',
		'ROLE_OPERATIONAL_EXCELLENCE_LEAD',
		'ROLE_ESG',
	],
	[roleGroups.EPC_PARTNER]: ['ROLE_EPC_PARTNER'],
};

// eslint-disable-next-line complexity
export const getRoleGroupByRole = role => {
	switch (role) {
		case roles.ADMIN:
		case roles.FINANCE:
		case roles.HEAD_OF_FINANCE:
		case roles.ASSET_MANAGER:
		case roles.ASSET_MARKETING:
		case roles.MANAGEMENT:
		case roles.PM_FUNDRAISING:
		case roles.PM_TECHNICAL:
		case roles.SALES:
		case roles.HEAD_OF_ASSET_MANAGEMENT:
		case roles.HEAD_OF_MARKETING:
		case roles.HEAD_OF_FUNDRAISING:
		case roles.HEAD_OF_TECH:
		case roles.HEAD_OF_SALES:
		case roles.HEAD_OF_REGION:
		case roles.HEAD_OF_EXCELLENCE:
		case roles.PRODUCT_LEAD:
		case roles.DATA_LEAD:
		case roles.OPERATIONAL_EXCELLENCE_LEAD:
		case roles.ESG:
			return roleGroups.ECOLIGO;

		case roles.EPC_PARTNER:
			return roleGroups.EPC_PARTNER;

		default:
			return null;
	}
};

export const ecoligoRoleOptions = [
	{
		value: roles.ADMIN,
		label: 'Admin',
	},
	{
		value: roles.FINANCE,
		label: 'Finance',
	},
	{
		value: roles.HEAD_OF_FINANCE,
		label: 'Head of Finance',
	},
	{
		value: roles.ASSET_MANAGER,
		label: 'Asset Management',
	},
	{
		value: roles.ASSET_MARKETING,
		label: 'Marketing',
	},
	{
		value: roles.MANAGEMENT,
		label: 'Management',
	},
	{
		value: roles.PM_FUNDRAISING,
		label: 'Fundraising',
	},
	{
		value: roles.PM_TECHNICAL,
		label: 'Tech',
	},
	{
		value: roles.SALES,
		label: 'Sales',
	},
	{
		value: roles.HEAD_OF_ASSET_MANAGEMENT,
		label: 'Head of Asset Management',
	},
	{
		value: roles.HEAD_OF_MARKETING,
		label: 'Head of Marketing',
	},
	{
		value: roles.HEAD_OF_FUNDRAISING,
		label: 'Head of Fundraising',
	},
	{
		value: roles.HEAD_OF_TECH,
		label: 'Head of Tech',
	},
	{
		value: roles.HEAD_OF_SALES,
		label: 'Head of Sales',
	},
	{
		value: roles.HEAD_OF_ESG,
		label: 'Head of ESG',
	},
	{
		value: roles.HEAD_OF_REGION,
		label: 'Head of Region',
	},
	{
		value: roles.HEAD_OF_EXCELLENCE,
		label: 'Head of Excellence',
	},
	{
		value: roles.PRODUCT_LEAD,
		label: 'Product Lead',
	},
	{
		value: roles.DATA_LEAD,
		label: 'Data Lead',
	},
	{
		value: roles.OPERATIONAL_EXCELLENCE_LEAD,
		label: 'Operational Excellence Lead',
	},
	{
		value: roles.ESG,
		label: 'ESG',
	},
];

export const epcRoleOptions = [
	{
		value: roles.EPC_PARTNER,
		label: 'EPC Partner',
	},
];

export const roleOptionsByRoleGroup = {
	[roleGroups.ECOLIGO]: ecoligoRoleOptions,
	[roleGroups.EPC_PARTNER]: epcRoleOptions,
};

export const roleOptions = [...roleOptionsByRoleGroup.ECOLIGO, ...roleOptionsByRoleGroup.EPC_PARTNER];
