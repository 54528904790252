/* eslint-disable react/prop-types */
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import UtilityRateModal from 'Client/components/Modals/UtilityRateModal';

import EditableTile from 'Common/components/Tile/EditableTile';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import PropTypes from 'prop-types';
// import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { MenuItem } from '@mui/material';
import UtilityRateViewModal from '../Modals/UtilityRateViewModal';
import formatDate from 'Common/utils/formatDate';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const UtilityList = ({ utilityRates, countryId, onDataChange }) => {
	const { t } = useTranslation();
	const isAuthorizedToCreate = useMemo(() => isAuthorized([roles.ADMIN, roles.FINANCE]), []);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [utilityRateName, setUtilityRateName] = useState(null);
	const [isViewModalOpen, setIsViewModalOpen] = useState(false);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Tariff Type'),
				accessor: 'name',
			},
			{
				Header: t('Rate'),
				accessor: 'rate',
			},
			{
				Header: t('Date'),
				accessor: 'createdDate',
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 100,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);
	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			utilityRates?.map(rate => ({
				...rate,
				createdDate: formatDate(rate.createdDate, 'yyyy/MM/dd'),
				actionDots: (
					<MoreActionsButton controlledVisibility>
						<MenuItem
							data-action="view"
							onClick={() => {
								setIsViewModalOpen(true);
								setUtilityRateName(rate.name);
							}}
							label="View Grid Utility Rate"
						>
							Show History
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, utilityRates],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
				getCustomRowStyles: () => ({
					height: 'auto',
				}),
			},
			useCustomRowStyle,
			useCustomCellStyles,
			// useClickableTableRow,
		);

	return (
		<>
			<EditableTile
				title={t('Grid Utility Rate')}
				isTable
				rightComponent={
					isAuthorizedToCreate ? (
						<TileActionButton
							data-button="create-utility-rate"
							onClick={() => {
								setIsModalOpen(true);
							}}
						/>
					) : null
				}
			>
				<TableWrapper>
					<DefaultTable
						getTableProps={getTableProps}
						getTableHeaderProps={getTableHeaderProps}
						getTableBodyProps={getTableBodyProps}
						rows={rows}
						headerGroups={headerGroups}
						prepareRow={prepareRow}
					/>
				</TableWrapper>
			</EditableTile>
			<UtilityRateModal
				isOpen={isModalOpen}
				countryId={countryId}
				onClose={() => setIsModalOpen(false)}
				onFormSubmit={onDataChange}
			/>
			<UtilityRateViewModal
				isOpen={isViewModalOpen}
				onClose={() => setIsViewModalOpen(false)}
				utilityRateName={utilityRateName}
			/>
		</>
	);
};

UtilityList.defaultProps = {
	utilityRates: [],
	onDataChange: () => null,
};

UtilityList.propTypes = {
	onDataChange: PropTypes.func.isRequired,
	utilityRates: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			date: PropTypes.string,
			rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	).isRequired,
};

export default UtilityList;
