import api from 'Application/api/api';

const getTariffTypeHistory = tariffName => {
	const params = {
		name: tariffName,
	};
	return api.get('/grid-utility-rate/get-history', { params });
};

export default getTariffTypeHistory;
