export const weightOptions = [
	{
		value: 1,
		label: '1',
	},
	{
		value: 2,
		label: '2',
	},
	{
		value: 3,
		label: '3',
	},
	{
		value: 4,
		label: '4',
	},
	{
		value: 5,
		label: '5',
	},
	{
		value: 6,
		label: '6',
	},
	{
		value: 7,
		label: '7',
	},
	{
		value: 8,
		label: '8',
	},
	{
		value: 9,
		label: '9',
	},
	{
		value: 10,
		label: '10',
	},
];

export const completedTypes = [
	{
		value: 'SET_AS_COMPLETED',
		label: 'Set as completed',
	},
	{
		value: 'REQUIRE_COMPLETION',
		label: 'Require completion',
	},
];

export const responsibleTeamOptions = [
	{
		value: 'ROLE_ASSET_MANAGER',
		label: 'Asset Management',
	},
	{
		value: 'ROLE_FINANCE',
		label: 'Finance',
	},
	{
		value: 'ROLE_HEAD_OF_FINANCE',
		label: 'Head of Finance',
	},
	{
		value: 'ROLE_PM_FUNDRAISING',
		label: 'Fundraising',
	},
	{
		value: 'ROLE_ASSET_MARKETING',
		label: 'Marketing',
	},
	{
		value: 'ROLE_SALES',
		label: 'Sales',
	},
	{
		value: 'ROLE_PM_TECHNICAL',
		label: 'Tech',
	},
	{
		value: 'ROLE_HEAD_OF_ASSET_MANAGEMENT',
		label: 'Head of Asset Management',
	},
	{
		value: 'ROLE_HEAD_OF_MARKETING',
		label: 'Head of Marketing',
	},
	{
		value: 'ROLE_HEAD_OF_FUNDRAISING',
		label: 'Head of Fundraising',
	},
	{
		value: 'ROLE_HEAD_OF_TECH',
		label: 'Head of Tech',
	},
	{
		value: 'ROLE_HEAD_OF_SALES',
		label: 'Head of Sales',
	},
	{
		value: 'ROLE_HEAD_OF_ESG',
		label: 'Head of ESG',
	},
	{
		value: 'ROLE_HEAD_OF_REGION',
		label: 'Head of Region',
	},
	{
		value: 'ROLE_HEAD_OF_EXCELLENCE',
		label: 'Head of Excellence',
	},
	{
		value: 'ROLE_PRODUCT_LEAD',
		label: 'Product Lead',
	},
	{
		value: 'ROLE_DATA_LEAD',
		label: 'Data Lead',
	},
	{
		value: 'ROLE_OPERATIONAL_EXCELLENCE_LEAD',
		label: 'Operational Excellence Lead',
	},
	{
		value: 'ROLE_ESG',
		label: 'ESG',
	},
];

export const projectTypes = [
	{
		value: 'PV',
		label: 'PV',
	},
	{
		value: 'EE',
		label: 'EE',
	},
	{
		value: 'SHW',
		label: 'SHW',
	},
	{
		value: 'ES',
		label: 'ES',
	},
];

export const statisticsOptions = [
	{
		value: 'TOTAL_PROJECTS_SIGNED',
		label: 'Total projects signed',
	},
	{
		value: 'TOTAL_CO2_SIGNED',
		label: 'Total CO2 signed',
	},
	{
		value: 'TOTAL_MWP_SIGNED',
		label: 'Total MWp signed',
	},
	{
		value: 'TOTAL_COUNTRIES',
		label: 'Total countries',
	},
	{
		value: 'TOTAL_COUNTRIES_SERVED',
		label: 'Total countries served',
	},
	{
		value: 'TOTAL_C02_FUNDED',
		label: 'Total CO2 funded',
	},
	{
		value: 'LOWEST_KWP_FUNDED',
		label: 'Lowest KWp funded',
	},
	{
		value: 'HIGHEST_KWP_FUNDED',
		label: 'Highest KWp funded',
	},
	{
		value: 'TOTAL_PROJECTS_FUNDED',
		label: 'Total projects funded',
	},
	{
		value: 'TOTAL_PROJECTS_IN_OPERATION',
		label: 'Total projects in Operation',
	},
	{
		value: 'TOTAL_CO2_IN_OPERATION',
		label: 'Total CO2 in operation',
	},
	{
		value: 'TOTAL_MWP_IN_OPERATION',
		label: 'Total MWp in operation',
	},
	{
		value: 'TOTAL_INDUSTRIES_SERVED',
		label: 'Total industries served',
	},
	{
		value: 'TOTAL_EMPLOYEES_IMPACTED',
		label: 'Total employees impacted',
	},
	{
		value: 'TOTAL_CLIENTS_IMPACTED',
		label: 'Total Clients impacted',
	},
	{
		value: 'TOTAL_MWP_SIGNED_ONLY',
		label: 'Total MWp signed only',
	},
	{
		value: 'TOTAL_OFFERS_SENT',
		label: 'Total offers sent',
	},
];
